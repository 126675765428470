@import 'vendor';
@import 'settings';


*, body {
  font-family: arial,sans-serif;
  font-size: 100%;
  font-weight: 400;
  color: $black;
}

img {
  border: 0;
}

ul {
  padding: 0;
  margin: 0;
}

ol {

  &.alpha {
    list-style-type: lower-alpha;
  }
}

a {
  &:active,
  &:hover,
  &:link,
  &:visited {
    cursor: pointer;
    color: $primary;
    text-decoration: none;
  }
}
