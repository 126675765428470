h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 1.80rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.375rem;
  margin-bottom: .625rem;
}

h3 {
  font-size: 1.125rem;
  padding: 5px 0;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size:1rem;
}
