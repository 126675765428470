.card-header {
  cursor: pointer;
  background: $black;

  h2 {
    color: $white;
  }
}


.alert {
  ul {
    margin: 0;
    list-style-type: none;
  }
}


.stealth {
  color: transparent;
  display: inline-block;
  width: 1px;
}

.btn-link {
  color: $primary;

  &:hover{
    color: $primary-dark;
  }
}

/* Privacy Block */
#doNotTrack {
  border: 0;
  max-height: 110px;
  width:100%;
}

.linkMarker {
  &::before {
    color: $white;
    content: '[';
    display: inline-block;
  }

  &::after {
    color: $white;
    content: ']';
    display: inline-block;
  }
}

#back-top {
  a {
    text-decoration: none;
    display: block;
    height: 42px;
    width: 42px;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    border: 1px solid $gray-light;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    transform: rotate(270deg);
    font-weight: 500;
  }
}
