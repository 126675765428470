header {
  background: $black;
  height: 118px;
  position: fixed;
  z-index: 99999;


  #mainMenu {
    background: $black;
    flex-wrap: nowrap;

    .navbar-toggler-icon {
      color: $white;
    }

    #logo {
      background: transparent url(../../../../public/images/logo.png) left no-repeat;
      height: 102px;
      padding-left: 120px;
      width: 320px;
      font-size: 50px !important;

      #tld {
        color: $primary;
      }

      #subTitle {
        color: $gray;
        font-size: 9px;
        position: absolute;
        width: 150px;
        font-weight: 600;
        bottom: 40px;
        margin-left: 25px
      }

      a {
        font-size: 26px;
        display: inline-block;
        width: 163px;
        position: relative;
        top: -10px;

        &:visited, &:link, &:active {
          color: $white;
        }
      }
    }

    #hauptmenue {
      margin-top: 8px;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;

        li {
          padding: 0 10px;

          a {
            color: $white;
            font-size: 12px;
            text-transform: uppercase;
            display: inline-block;
            line-height: 26px;
            padding: 0 2px;

            .act {
              background: $primary;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:992px) {
	#hauptmenue {
    position: absolute;
    top: 110px;
    width: 100vw;
    background: $black;
    left: -15px;
    padding: 10px
  }
}

@media only screen and (max-width:600px) {
	#mainMenu {

    #logo {
      width: 240px;
      background: $black;
      padding-left: 0;
      height: 90px;
      margin-right: 0;

      a {
        width: 90px;
        font-size: 15px;
        top: -15px;
        margin: 0 15px
      }

      #subTitle {
        bottom: 33px;
        margin-left: 18px;
      }
    }

    #hauptmenue {
      ul {
        flex-direction: column;

        li {
          padding: 10px;
          font-size: 1.5rem;
          text-align: center;

          a {
            width: 80vw;
          }
        }
      }
    }
  }
}
