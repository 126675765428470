footer {
  #smallMenu {
    ul {
      list-style-type: none;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;

      li {
        display: block;
        padding: 0 10px;

        a {
          font-size: 12px;
          text-transform: uppercase;
          display: inline-block;
          line-height: 26px;
          padding: 0 2px;

          &.act {
            color: $white;
          }
        }

        &.linkMarker {
          &::before, &::after {
            color: $black;
          }
        }
      }
    }
  }
}
