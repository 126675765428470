main, .main {
  font-size: 1rem;
  display: block;
  padding: 140px 15px 25px 15px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  p {
    margin: .375rem 0;
  }

  ul, ol {
    margin-bottom: 1rem;
    padding-left: 2.5rem;
  }
}
